//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-736:_2848,_8880,_4564,_1252,_7412,_4884,_3008,_3532;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-736')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-736', "_2848,_8880,_4564,_1252,_7412,_4884,_3008,_3532");
        }
      }catch (ex) {
        console.error(ex);
      }