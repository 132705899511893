import shopifyChromiumDefaults from '../shopify-chromium/config.js';

export default {
  includes: ['shopify-chromium'],
  ...shopifyChromiumDefaults,
  Widgets: [
    ...shopifyChromiumDefaults.Widgets.filter(
      (w) =>
        ![
          'CategoryPage',
          'CategoryVehicleTitle',
          'CategoryFacetPanel',
          'DesktopGarage',
          'MobileGarage',
          'VerifyFitment',
          'FitmentTable',
          'FitmentTableTab',
          'HomeTabSearchBox',
        ].includes(w.name),
    ),
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#main-layout',
      template: 'MainContent',
    },
    {
      name: 'CategoryFacetPanel',
      type: 'FacetPanel',
      location: 'body.template-collection .featured_block-category',
      template: 'FacetPanelContainer',
    },
    {
      name: 'DesktopGarage',
      type: 'Garage',
      location: {
        insertAfter: '.header-content .header-helpline',
        class: 'd-none d-lg-block cm_garage__desktop',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        insertBefore: '.header-content .header-cart',
        wrapper: 'li',
        class: 'cm_garage__mobile',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      location: { insertAfter: 'body.template-product-default .product_price' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: 'body.template-product-default #product_fitment_tab',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: '.product_fitment_tab',
      template: 'fitmentSearch/fitmentTableTab',
    },
  ],
};
